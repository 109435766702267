
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import PaginationUi from "@/components/ABilling/PaginationXS.vue";
import { search } from "@/api/serviceBusMessage.api";
import useFilters from "@/modules/common/useFilters";
import debounce from "lodash.debounce";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}

export interface Data {
  items: [];
  search: string;
  orderBy: string;
  advancedOrderBy: any;
  isDecr: boolean;
  displayInactive: boolean;
  pagination: Pagination;
}

export default defineComponent({
  name: "ServiceMessagePage",
  components: { PaginationUi },
  setup() {
    const { orderList } = useFilters();
    orderList.value = [
      {
        name: "Created Date",
        key: "createdDate",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Message Id",
        key: "messageId",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Content Type",
        key: "contentType",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Emr OrgId",
        key: "emrOrgId",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Emr RehabId",
        key: "emrRehabId",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Billing TenantId",
        key: "billingTenantId",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Billing RehabId",
        key: "billingRehabId",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Error",
        key: "error",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Is Processed",
        key: "isProcessed",
        isFilter: false,
        keyword: null,
      },
    ];
    let data = reactive<Data>({
      items: [],
      search: "",
      orderBy: "userId",
      advancedOrderBy: undefined,
      displayInactive: true,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
    });

    onMounted(async () => {
      await getAll();
    });

    async function selectFilter(header) {
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;

      await getAll();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }
      let request = {
        globalSearch: data.search,
        advancedOrderBy: data.advancedOrderBy,
        isActive: data.displayInactive,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: [order],
      };

      const res = await search(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function debounceSearch() {
      await debounce(searchGlobal, useFilters().debounceMs)();
    }

    async function searchGlobal() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    return {
      debounceSearch,
      orderList,
      selectFilter,
      getAll,
      getSortInfo,
      data,
      pageChanged,
      pageSizeChanged,
    };
  },
});
